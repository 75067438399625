import Vue from "vue"
import { conf } from "utils.vue"

import * as Sentry from "@sentry/browser"
import { Vue as VueIntegration, RewriteFrames } from "@sentry/integrations"

const dsn = _.get(conf, "sentry.dsn")
const stage = _.get(conf, "stage")

if (_.present(dsn)) {

  Sentry.init({
    dsn,

    integrations: [
      new VueIntegration({ Vue, attachProps: true, logErrors: true }),
      new RewriteFrames()
    ],

    // release: TODO: definir padrão para nomeação de release
    // esse "environment" é o que consideramos "stage": "dev", "qa", "homolog", "production", ...
    // - não confundí-lo com o NODE_ENV
    environment: stage
  })

}
